import brand1Img from "../../../assets/images/brands/01.svg";
import brand2Img from "../../../assets/images/brands/02.svg";
import brand3Img from "../../../assets/images/brands/03.svg";
import brand4Img from "../../../assets/images/brands/04.svg";
import brand5Img from "../../../assets/images/brands/05.svg";
import brand6Img from "../../../assets/images/brands/06.svg";

import step from "@/assets/images/landing/warranty/step.png";
import step2 from "@/assets/images/landing/warranty/step2.png";
import step3 from "@/assets/images/landing/warranty/step3.png";
import step4 from "@/assets/images/landing/warranty/step4.png";

import avatar40Img from "../../../assets/images/avatar/40.jpg";
import avatar6Img from "../../../assets/images/avatar/06.jpg";
import avatar41Img from "../../../assets/images/avatar/41.jpg";
import avatar5Img from "../../../assets/images/avatar/05.jpg";

import chatImg from "@/assets/images/landing/inspection/features/test.png";
import analyticsImg from "@/assets/images/landing/inspection/features/2.png";
import bellImg from "@/assets/images/landing/inspection/features/presentation.png";
import tasksImg from "@/assets/images/landing/inspection/features/chat.png";

import type {
  BrandType,
  FeatureType,
  WorkFlowType,
  TestimonialType,
  UserType,
} from "@/types/cartrustAgentType";

type FaqsType = {
  question: string;
  answers: string[];
  question_arabic: string;
  answer_arabic: string[];
};
export const featuresData: FeatureType[] = [
  {
    image: chatImg,
    title: "Increase Your Income", // English translation
    title_arabic: "زيادة دخلك", // Arabic title
    description:
      "Earn over 12,000 SAR per month by promoting your unique promo code for CarTrust's premium inspection and warranty services.", // English description
    description_arabic:
      "احصل على دخل يصل إلى اكثر من 12,000 الف ريال في الشهر عن طريق تسويقك لرمزك الترويجي الخاص بك لخدمات فحص وضمان كار ترست المميزة", // Arabic description
  },
  {
    image: analyticsImg,
    title: "Specialized Training", // English translation
    title_arabic: "تدريب متخصص", // Arabic title
    description:
      "Once approved as a promoter, you can join training sessions by CarTrust experts to help you easily market and spread CarTrust services!", // English description
    description_arabic:
      "عند اعتمادك كمسوق، يمكنك الانضمام لدورات تدريبية مقدمة من خبراء كار ترست تساعدك في تسويق ونشر خدمات كار ترست بكل سهولة!", // Arabic description
  },
  {
    image: bellImg,
    title: "In-Demand Services", // English translation
    title_arabic: "خدمات مطلوبة", // Arabic title
    description:
      "Everyone in the used car market needs reliable inspections and warranties to make informed purchasing decisions.", // English description
    description_arabic:
      "الجميع في سوق المركبات المستعملة بحاجة الى عمليات فحص وضمان موثوقه للمساعدة في اتخاذ القرار السليم بالشراء", // Arabic description
  },
  {
    image: tasksImg,
    title: "Identify Your Clients", // English translation
    title_arabic: "عملاءك معروفين", // Arabic title
    description:
      "Track the clients who benefit from CarTrust services using your unique promo code.", // English description
    description_arabic:
      "تقدر تعرف العملاء المستفيدين من خدمات كارترست عن طريق الرمز الخاص بك", // Arabic description
  },
];

export const featuresData3: FeatureType[] = [
  {
    image: step,
    title: "Register", // English translation
    title_arabic: "سجل", // Arabic title
    description:
      "Register your information to join the CarTrust program family.", // English translation
    description_arabic: "سجل معلوماتك لكي تنضم لعائلة مسوقي برنامج كار ترست", // Arabic description
  },
  {
    image: step2,
    title: "Get the Code",
    title_arabic: "احصل  على الرمز",
    description:
      "Get your unique code after being approved for the CarTrust marketer program.",
    description_arabic:
      "احصل على رمزك الخاص بعد الموافقة في برنامج مسوق كار ترست",
  },
  {
    image: step3,
    title: "Promote",
    title_arabic: "سوّق",
    description: "Share your promotional code with your clients.",
    description_arabic: "شارك مع عملائك الرمز الترويجي الخاص بك",
  },
  {
    image: step4,
    title: "Earn",
    title_arabic: "اكسب",
    description:
      "Earn your commission with every purchase made through CarTrust platforms.",
    description_arabic:
      "اكسب عمولتك مع كل عملية شراء من خلال منصات خدمات كار ترست",
  },
];

export const brandData: BrandType[] = [
  {
    image: brand1Img,
  },
  {
    image: brand2Img,
  },
  {
    image: brand3Img,
  },
  {
    image: brand4Img,
  },
  {
    image: brand5Img,
  },
  {
    image: brand6Img,
  },
];

export const workFlowData: WorkFlowType[] = [
  {
    title: "Registration",
    description: "Massa enim libero dictumst consectetur in convallis lobortis",
  },
  {
    title: "Identity verification",
    description:
      "At ultricies id non quisque integer eget velit. Facilisis enim",
  },
  {
    title: "Start trading",
    description: "Pellentesque rhoncus viverra vestibulum, purus purus",
  },
  {
    title: "Earn with Silicon",
    description: "Massa enim libero dictumst consectetur in convallis lobortis",
  },
];

export const userData: UserType[] = [
  {
    image: avatar40Img,
    name: "Jenny Wilson",
    role: "Founder of Lorem Company",
  },
  {
    image: avatar6Img,
    name: "Wade Warren",
    role: "CEO of Ipsum Company",
  },
  {
    image: avatar41Img,
    name: "Ralph Edwards",
    role: "Founder of the Agency",
  },
  {
    image: avatar5Img,
    name: "Ralph Edwards",
    role: "CEO of Dolor Ltd.",
  },
];

export const testimonialData: TestimonialType[] = [
  {
    comment:
      "This app has completely transformed the way I approach my finances. It's packed with useful features and it's helped me stay on top of my finances and reach my financial goals.",
    rating: 5,
    user: userData[0],
  },
  {
    comment: `I absolutely love using this app! It's user-friendly interface makes it incredibly easy to track my spending and budget accordingly. I highly recommend it to anyone looking to improve their financial management skills.`,
    rating: 4,
    user: userData[1],
  },
  {
    comment: `I've tried many different apps, but this one stands out as the best. Its intuitive interface, helpful tools, and insightful reports have made it an essential tool for managing my money.`,
    rating: 5,
    user: userData[2],
  },
  {
    comment:
      "This app has completely transformed the way I approach my finances. It's packed with useful features and it's helped me stay on top of my finances and reach my financial goals.",
    rating: 4,
    user: userData[3],
  },
];

export const faqsData: FaqsType[] = [
  {
    question_arabic: `ماهو برنامج مسوق كارترست؟`,
    answer_arabic: [
      `برنامج هدفه إيصال خدمات كار ترست لكل شخص يحتاج الفحص قبل الشراء او الضمان الممتد. وجنوده هم أنتم. وجهودكم يمكن تتبعها ومكافأتكم عليها بارباحكم المستحقة`,
    ],
    question: `What is the CarTrust Agent Program?`,
    answers: [
      `A program aimed at delivering CarTrust services to anyone who needs pre-purchase inspection or extended warranty. You are the agents, and your efforts are trackable and rewarded with your earned profits.`,
    ],
  },
  {
    question_arabic: `ماهي النقاط المستحقة لي؟`,
    answer_arabic: [`جميع المبالغ مشروحة في جدول الارباح مقابل كل خدمة`],
    question: `What are my earned points?`,
    answers: [
      `All amounts are explained in the earnings table for each service.`,
    ],
  },
  {
    question_arabic: `كيف أصبح مسوق ناجح`,
    answer_arabic: [
      `سجل عبر الرابط الخاص بالانضمام واملأ المعلومات اللازمة، وسيصلك بعدها مباشرة الرابط والكود الخاص بك في برنامج مسوق كارترست`,
    ],
    question: `How do I become a successful agent?`,
    answers: [
      `Register via the provided link and fill in the required information. You will immediately receive your unique link and code for the CarTrust Agent Program.`,
    ],
  },
  {
    question_arabic: `هل توجد شروط للانضمام؟`,
    answer_arabic: [`شرطنا الوحيد هو رغبتك بالانضمام للبرنامج!`],
    question: `Are there any requirements to join?`,
    answers: [`Our only requirement is your willingness to join the program!`],
  },
  {
    question_arabic: `هل هناك أي تكاليف مرتبطة بالانضمام الي البرنامج؟`,
    answer_arabic: [
      `الانضمام مجاني تمامًا. وبتكسب معه ارباح ودورات تسويق متخصصة`,
    ],
    question: `Are there any costs associated with joining the program?`,
    answers: [
      `Joining is completely free. You'll earn profits and gain access to specialized marketing courses.`,
    ],
  },
  {
    question_arabic: `كيف أتمكن من تتبع المبيعات؟`,
    answer_arabic: [
      `كل مسوّق ينضم للبرنامج له رابط وكود خاص به. عند استخدام عميلك الكود في خدمات كارترست. يتم رصد العملية وإسنادها لحسابك`,
    ],
    question: `How can I track sales?`,
    answers: [
      `Every agent in the program gets a unique link and code. When your client uses the code for CarTrust services, the transaction is tracked and attributed to your account.`,
    ],
  },
  {
    question_arabic: `كم مرة يتم دفع الارباح؟`,
    answer_arabic: [`نحوّل لك الارباح كل شهر على حسابك البنكي`],
    question: `How often are profits paid out?`,
    answers: [`We transfer your profits to your bank account every month.`],
  },
  {
    question_arabic: `هل هناك أي دعم مقدم للمسوقين؟`,
    answer_arabic: [
      `فريقنا موجود لإجابة كل سؤال يجي على بالك. حتى لو كان “بكم زيت الفراميل؟`,
    ],
    question: `Is there any support provided to agents?`,
    answers: [
      `Our team is here to answer any questions you might have, even if it's "How much is brake fluid?"`,
    ],
  },
  {
    question_arabic: `هل يوجد كود خصم للمسوق؟`,
    answer_arabic: [
      `لكل مسوق إمكانية الخصم على سعر الباقة، تواصل معنا للحصول على الخصم`,
    ],
    question: `Is there a discount code for agents?`,
    answers: [
      `Every agent has the option to apply a discount on the package price. Contact us to get the discount.`,
    ],
  },
];
