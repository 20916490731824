import { default as _91_46_46_46slug_93RDTJWBz6I4Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/[...slug].vue?macro=true";
import { default as About1FooterViG3ARxP63Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/About1Footer.vue?macro=true";
import { default as About1HeroQUDlGpRgxuMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/About1Hero.vue?macro=true";
import { default as AwardsjRsj4DW4H8Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Awards.vue?macro=true";
import { default as BenefitskVJ10jXDh5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Benefits.vue?macro=true";
import { default as Contactq2b8jn6kUYMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Contact.vue?macro=true";
import { default as SocialNetworkqQRgiAqcH5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/SocialNetwork.vue?macro=true";
import { default as TeamslmaP1W9YNMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Team.vue?macro=true";
import { default as TeamCardi1R9fZbJbMMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/TeamCard.vue?macro=true";
import { default as TestimonialWvLpHdbOrXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Testimonial.vue?macro=true";
import { default as TestimonialCardo2GvJGKShKMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/TestimonialCard.vue?macro=true";
import { default as VideoReelY5wmmzXjYhMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/VideoReel.vue?macro=true";
import { default as dataKi6AGlCxABMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/data.ts?macro=true";
import { default as indexC7lJSFUqWQMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/index.vue?macro=true";
import { default as indexHyvHwFqkwcMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/branches/index.vue?macro=true";
import { default as index8uMWiaPHC2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cancel/index.vue?macro=true";
import { default as index3HUeaTum7xMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/career/index.vue?macro=true";
import { default as FaqslgyZB8xqQdMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Faqs.vue?macro=true";
import { default as FeaturesglcAXYFsaNMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Features.vue?macro=true";
import { default as OpportunitiesmAy7ovvNHIMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Opportunities.vue?macro=true";
import { default as PlanComparisonPUSnlWlITqMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/PlanComparison.vue?macro=true";
import { default as PricingJmCJoZiUfxMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Pricing.vue?macro=true";
import { default as Saas3HerokTg0RpPwxBMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Saas3Hero.vue?macro=true";
import { default as TestimonialzVFms86If2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Testimonial.vue?macro=true";
import { default as TestimonialCardvtJPhjsHVhMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/TestimonialCard.vue?macro=true";
import { default as WorkNqpLdazYueMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Work.vue?macro=true";
import { default as datagXWV9yrX6OMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/data.ts?macro=true";
import { default as indexT01z0P01H1Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/index.vue?macro=true";
import { default as indexgpOtpHs9NfMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/checkout/index.vue?macro=true";
import { default as Contacts1FooteruFH9ofTMdHMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Contacts1Footer.vue?macro=true";
import { default as Contacts1HeroGsgNebMwS5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Contacts1Hero.vue?macro=true";
import { default as DetailsEZcW0DUtaBMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Details.vue?macro=true";
import { default as Faqs1H40WnSjRRMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Faqs.vue?macro=true";
import { default as dataVvZz6TX0wLMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/data.ts?macro=true";
import { default as indexWSxAutHKlQMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/index.vue?macro=true";
import { default as index3DSExDRhtfMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/coupons/index.vue?macro=true";
import { default as indexBhK8qzFTYSMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/index.vue?macro=true";
import { default as inspectionsax7r6HsLuFMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/inspections.vue?macro=true";
import { default as indexNe0FXb7uXoMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/profile/index.vue?macro=true";
import { default as transaction40m0j1fqDaMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/transaction.vue?macro=true";
import { default as index04Htf83BVQMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/user-scan-list/index.vue?macro=true";
import { default as vehiclesf59Gv3RCTNMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/vehicles.vue?macro=true";
import { default as wallethw7MIK1VJxMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/wallet.vue?macro=true";
import { default as warrantiesqXEdXgE6EEMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/warranties.vue?macro=true";
import { default as ContentIbmj4e8jjtMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/data-products-disclaimer/components/Content.vue?macro=true";
import { default as indexk4Rrq9MgolMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/data-products-disclaimer/index.vue?macro=true";
import { default as BrandsE3HDFtp4gxMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Brands.vue?macro=true";
import { default as CarSearchBoxeFOEfAt6eLMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/CarSearchBox.vue?macro=true";
import { default as ContactjEirgnPWmiMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Contact.vue?macro=true";
import { default as CovidBannerUUjHs31wYeMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/CovidBanner.vue?macro=true";
import { default as FeaturewO4RJaNtnTMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Feature.vue?macro=true";
import { default as FinancialFooterTHGbsr5fceMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/FinancialFooter.vue?macro=true";
import { default as FinancialHeroftb52wvPx5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/FinancialHero.vue?macro=true";
import { default as IndustrieSwipern1uaIjJJu7Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/IndustrieSwiper.vue?macro=true";
import { default as NewsCardKVDYlwiG7qMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/NewsCard.vue?macro=true";
import { default as NewsSwiper5vdRF5rvHwMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/NewsSwiper.vue?macro=true";
import { default as PricingqVlObOwvYjMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Pricing.vue?macro=true";
import { default as PurchaseLRKDh9wMiqMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Purchase.vue?macro=true";
import { default as ServicesiWv1gSmE5jMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Services.vue?macro=true";
import { default as Sponsorsxnb4i7JQVkMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Sponsors.vue?macro=true";
import { default as TestimonialEIlILcU2cyMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Testimonial.vue?macro=true";
import { default as TestimonialCardwhqGROy9xRMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/TestimonialCard.vue?macro=true";
import { default as dataot4kZ4WkPkMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/data.ts?macro=true";
import { default as indexlJ2D6PeADRMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/index.vue?macro=true";
import { default as indexqujjdqHaiLMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/[id]/index.vue?macro=true";
import { default as feedbacks3goOTqpDwMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/Components/feedback.vue?macro=true";
import { default as ReEvaluation2iuxCYIpg1Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/Components/ReEvaluation.vue?macro=true";
import { default as indexhhLA8wrlkdMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/index.vue?macro=true";
import { default as indexGVsyr8TbR2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/fail/index.vue?macro=true";
import { default as Contacts1HerotGQjqT6f3JMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/components/Contacts1Hero.vue?macro=true";
import { default as DetailscEZrsjjEeVMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/components/Details.vue?macro=true";
import { default as FaqsbZdOZoIFgRMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/components/Faqs.vue?macro=true";
import { default as datafjik97vmKCMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/data.ts?macro=true";
import { default as indexMt4hGxByKEMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/index.vue?macro=true";
import { default as HeroSectionsqJpDPFKaSMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/components/HeroSection.vue?macro=true";
import { default as SearchCarKpTxLig0ffMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/components/SearchCar.vue?macro=true";
import { default as SpecificationstGB3eAUOheMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/components/Specifications.vue?macro=true";
import { default as indexNHSSNO33vhMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/index.vue?macro=true";
import { default as ContentZpRitQiirdMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/impartiality-policy/components/Content.vue?macro=true";
import { default as indexmqvX87d7e1Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/impartiality-policy/index.vue?macro=true";
import { default as index0vTwMH94WEMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/index.vue?macro=true";
import { default as CarSearchBoxKuC15rqcXtMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/CarSearchBox.vue?macro=true";
import { default as ComparisonSlider86QyZkqDpfMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/ComparisonSlider.vue?macro=true";
import { default as CtaCGNn1GpHCbMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Cta.vue?macro=true";
import { default as FeaturesvCKIAd5VQfMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Features.vue?macro=true";
import { default as Features2F14G9JUm2OMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Features2.vue?macro=true";
import { default as InspectionTypesDDNF2YogiNMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/InspectionTypes.vue?macro=true";
import { default as Integrationsm28JwWStlQMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Integrations.vue?macro=true";
import { default as Saas1FooteruuA2cfDwl4Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Saas1Footer.vue?macro=true";
import { default as Saas1Hero7mWRvceOT5Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Saas1Hero.vue?macro=true";
import { default as Testimonialk1VfMWzHCxMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Testimonial.vue?macro=true";
import { default as TestimonialCardpQsyFpskDcMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/TestimonialCard.vue?macro=true";
import { default as UseCaseZJ4hrvx3TjMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/UseCase.vue?macro=true";
import { default as WorkolTVOojAXiMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Work.vue?macro=true";
import { default as datagwX0SJwdPOMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/data.ts?macro=true";
import { default as indexIy1AtPIKcIMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/index.vue?macro=true";
import { default as indexeS6vly4KAPMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspections/[id]/index.vue?macro=true";
import { default as indexGuB7zTzvlDMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspections/index.vue?macro=true";
import { default as image504kY5oLtjMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/assets/data/image.ts?macro=true";
import { default as BrandsvsGSVmB1fuMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Brands.vue?macro=true";
import { default as Cta2UWHUiCHMFMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Cta.vue?macro=true";
import { default as FaqsosVxWYfvpmMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Faqs.vue?macro=true";
import { default as Features1bCXGEbvQ7DMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Features1.vue?macro=true";
import { default as Features26eU8dIInJNMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Features2.vue?macro=true";
import { default as MobileApp1FooterUJaUbWV3AgMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/MobileApp1Footer.vue?macro=true";
import { default as MobileApp1HerobXWsvZcayvMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/MobileApp1Hero.vue?macro=true";
import { default as ProcessSwiperKJxRFJQUlmMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/ProcessSwiper.vue?macro=true";
import { default as TestimonialaQSbcI0K9rMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Testimonial.vue?macro=true";
import { default as TestimonialCard46VVD0tTjzMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/TestimonialCard.vue?macro=true";
import { default as dataqTFqpmeMWSMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/data.ts?macro=true";
import { default as indexlzj2oa5G7iMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/index.vue?macro=true";
import { default as AnchorNavigationQfRrFkjN2rMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue?macro=true";
import { default as BreadcrumbDKPVXwGo13Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue?macro=true";
import { default as CustomSyntax7dk4WhG0krMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue?macro=true";
import { default as Jarallax9ZX15W8HKmMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue?macro=true";
import { default as LightGalleryrWOh4Nmx19Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue?macro=true";
import { default as LoadingSpinnerk0mmBLzT12Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue?macro=true";
import { default as ParallaxLnCgEdyAykMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue?macro=true";
import { default as PasswordInputoMRvEkRhv6Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue?macro=true";
import { default as RellaxdGFPQSXwEXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue?macro=true";
import { default as VanillaTilthplh39HAZAMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue?macro=true";
import { default as VideoPopupV6JRaCgoRXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue?macro=true";
import { default as Contact4wdtP2xNWxMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Contact.vue?macro=true";
import { default as CtaLcn1udSS76Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Cta.vue?macro=true";
import { default as FeatureH0mCzE6fFrMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Feature.vue?macro=true";
import { default as FeatureCardT9epBAAvfKMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/FeatureCard.vue?macro=true";
import { default as MedicalFooterii4CNIVJyVMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/MedicalFooter.vue?macro=true";
import { default as MedicalHerovJI5qvvNfXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/MedicalHero.vue?macro=true";
import { default as NewsilndKsTtlnMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/News.vue?macro=true";
import { default as NewsCardovlQINSJEeMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/NewsCard.vue?macro=true";
import { default as ServiceCardENxhE2KNSHMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/ServiceCard.vue?macro=true";
import { default as ServicesCLAXgYuXczMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Services.vue?macro=true";
import { default as Team5TkSkepv8CMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Team.vue?macro=true";
import { default as TeamCard5QwC4NPYq7Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/TeamCard.vue?macro=true";
import { default as Testimonialk3vePHPIHMMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Testimonial.vue?macro=true";
import { default as TestimonialCardemUH1LWOKjMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/TestimonialCard.vue?macro=true";
import { default as VideoReelENFuf1alXBMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/VideoReel.vue?macro=true";
import { default as datayZw48NNMY7Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/data.ts?macro=true";
import { default as indexvIP8hZO7R3Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/index.vue?macro=true";
import { default as ArticleCardq0rYzkokDqMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/ArticleCard.vue?macro=true";
import { default as ArticlesBTopPWYMxhMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/Articles.vue?macro=true";
import { default as CommentKgFEpRkFZ2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/Comment.vue?macro=true";
import { default as CommentFormOHyBOwoFfnMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/CommentForm.vue?macro=true";
import { default as Jarallax2KtPsIyATLMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/Jarallax.vue?macro=true";
import { default as LightGalleryO80oNX9KetMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/LightGallery.vue?macro=true";
import { default as SinglePostHeromluK9JdGXUMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/SinglePostHero.vue?macro=true";
import { default as dataDAxZt1dqXUMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/data.ts?macro=true";
import { default as indexJ4m82KR09XMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/index.vue?macro=true";
import { default as BlogCardJ5Q2ZB7eNaMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/components/BlogCard.vue?macro=true";
import { default as ContentYqF62bXfDYMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/components/Content.vue?macro=true";
import { default as Paginationc3taJjoAoAMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/components/Pagination.vue?macro=true";
import { default as dataEtkNwyrYeGMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/data.ts?macro=true";
import { default as index58NQmxYdQ7Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/index.vue?macro=true";
import { default as indexGp6cIprigZMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/our-clients/index.vue?macro=true";
import { default as indexexGx1n1OlgMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/partners/index.vue?macro=true";
import { default as indexv5wfaEd2YYMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/payment/redirect/index.vue?macro=true";
import { default as ContentHApWkEqOs6Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/privacy-policy/components/Content.vue?macro=true";
import { default as indexp4bAzB3xz3Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/privacy-policy/index.vue?macro=true";
import { default as indexmY4KGrL1poMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/sales-agent-form/index.vue?macro=true";
import { default as indexv1tEafQWP2Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/success/index.vue?macro=true";
import { default as Content4ALuKc8WuvMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/terms-and-condition/components/Content.vue?macro=true";
import { default as indexnjKZ45cuOWMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/terms-and-condition/index.vue?macro=true";
import { default as indexOGyJkDqnAZMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/trailer-inspection-and-half-trailers/index.vue?macro=true";
import { default as indexxYxpfWDFg7Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/trim/index.vue?macro=true";
import { default as indexK7uR2g3gu8Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/vin-decoder/index.vue?macro=true";
import { default as indexR4G8fXNmh6Meta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranties/index.vue?macro=true";
import { default as index5ZvmeyB6NuMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form/index.vue?macro=true";
import { default as AppFeatureJhLbvTfm5BMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/AppFeature.vue?macro=true";
import { default as CarSearchBox3LQxjYJD0VMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/CarSearchBox.vue?macro=true";
import { default as Pricing2uNtK6buSXMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Pricing.vue?macro=true";
import { default as PricingForMobilec5GhldnCqlMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/PricingForMobile.vue?macro=true";
import { default as Saas3HeropxLGvZOQtaMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Saas3Hero.vue?macro=true";
import { default as Testimonial9RhcLsPTrBMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Testimonial.vue?macro=true";
import { default as TestimonialCardZVlpyMNdYEMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/TestimonialCard.vue?macro=true";
import { default as Work1DnjVnLR8lMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Work.vue?macro=true";
import { default as datasEnBISx73UMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/data.ts?macro=true";
import { default as indexwXkA4sWPgsMeta } from "C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93RDTJWBz6I4Meta?.name ?? "slug",
    path: _91_46_46_46slug_93RDTJWBz6I4Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93RDTJWBz6I4Meta || {},
    alias: _91_46_46_46slug_93RDTJWBz6I4Meta?.alias || [],
    redirect: _91_46_46_46slug_93RDTJWBz6I4Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: About1FooterViG3ARxP63Meta?.name ?? "about-components-About1Footer",
    path: About1FooterViG3ARxP63Meta?.path ?? "/about/components/About1Footer",
    meta: About1FooterViG3ARxP63Meta || {},
    alias: About1FooterViG3ARxP63Meta?.alias || [],
    redirect: About1FooterViG3ARxP63Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/About1Footer.vue").then(m => m.default || m)
  },
  {
    name: About1HeroQUDlGpRgxuMeta?.name ?? "about-components-About1Hero",
    path: About1HeroQUDlGpRgxuMeta?.path ?? "/about/components/About1Hero",
    meta: About1HeroQUDlGpRgxuMeta || {},
    alias: About1HeroQUDlGpRgxuMeta?.alias || [],
    redirect: About1HeroQUDlGpRgxuMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/About1Hero.vue").then(m => m.default || m)
  },
  {
    name: AwardsjRsj4DW4H8Meta?.name ?? "about-components-Awards",
    path: AwardsjRsj4DW4H8Meta?.path ?? "/about/components/Awards",
    meta: AwardsjRsj4DW4H8Meta || {},
    alias: AwardsjRsj4DW4H8Meta?.alias || [],
    redirect: AwardsjRsj4DW4H8Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Awards.vue").then(m => m.default || m)
  },
  {
    name: BenefitskVJ10jXDh5Meta?.name ?? "about-components-Benefits",
    path: BenefitskVJ10jXDh5Meta?.path ?? "/about/components/Benefits",
    meta: BenefitskVJ10jXDh5Meta || {},
    alias: BenefitskVJ10jXDh5Meta?.alias || [],
    redirect: BenefitskVJ10jXDh5Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Benefits.vue").then(m => m.default || m)
  },
  {
    name: Contactq2b8jn6kUYMeta?.name ?? "about-components-Contact",
    path: Contactq2b8jn6kUYMeta?.path ?? "/about/components/Contact",
    meta: Contactq2b8jn6kUYMeta || {},
    alias: Contactq2b8jn6kUYMeta?.alias || [],
    redirect: Contactq2b8jn6kUYMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: SocialNetworkqQRgiAqcH5Meta?.name ?? "about-components-SocialNetwork",
    path: SocialNetworkqQRgiAqcH5Meta?.path ?? "/about/components/SocialNetwork",
    meta: SocialNetworkqQRgiAqcH5Meta || {},
    alias: SocialNetworkqQRgiAqcH5Meta?.alias || [],
    redirect: SocialNetworkqQRgiAqcH5Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/SocialNetwork.vue").then(m => m.default || m)
  },
  {
    name: TeamslmaP1W9YNMeta?.name ?? "about-components-Team",
    path: TeamslmaP1W9YNMeta?.path ?? "/about/components/Team",
    meta: TeamslmaP1W9YNMeta || {},
    alias: TeamslmaP1W9YNMeta?.alias || [],
    redirect: TeamslmaP1W9YNMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Team.vue").then(m => m.default || m)
  },
  {
    name: TeamCardi1R9fZbJbMMeta?.name ?? "about-components-TeamCard",
    path: TeamCardi1R9fZbJbMMeta?.path ?? "/about/components/TeamCard",
    meta: TeamCardi1R9fZbJbMMeta || {},
    alias: TeamCardi1R9fZbJbMMeta?.alias || [],
    redirect: TeamCardi1R9fZbJbMMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/TeamCard.vue").then(m => m.default || m)
  },
  {
    name: TestimonialWvLpHdbOrXMeta?.name ?? "about-components-Testimonial",
    path: TestimonialWvLpHdbOrXMeta?.path ?? "/about/components/Testimonial",
    meta: TestimonialWvLpHdbOrXMeta || {},
    alias: TestimonialWvLpHdbOrXMeta?.alias || [],
    redirect: TestimonialWvLpHdbOrXMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardo2GvJGKShKMeta?.name ?? "about-components-TestimonialCard",
    path: TestimonialCardo2GvJGKShKMeta?.path ?? "/about/components/TestimonialCard",
    meta: TestimonialCardo2GvJGKShKMeta || {},
    alias: TestimonialCardo2GvJGKShKMeta?.alias || [],
    redirect: TestimonialCardo2GvJGKShKMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: VideoReelY5wmmzXjYhMeta?.name ?? "about-components-VideoReel",
    path: VideoReelY5wmmzXjYhMeta?.path ?? "/about/components/VideoReel",
    meta: VideoReelY5wmmzXjYhMeta || {},
    alias: VideoReelY5wmmzXjYhMeta?.alias || [],
    redirect: VideoReelY5wmmzXjYhMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/components/VideoReel.vue").then(m => m.default || m)
  },
  {
    name: dataKi6AGlCxABMeta?.name ?? "about-data",
    path: dataKi6AGlCxABMeta?.path ?? "/about/data",
    meta: dataKi6AGlCxABMeta || {},
    alias: dataKi6AGlCxABMeta?.alias || [],
    redirect: dataKi6AGlCxABMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/data.ts").then(m => m.default || m)
  },
  {
    name: indexC7lJSFUqWQMeta?.name ?? "about",
    path: indexC7lJSFUqWQMeta?.path ?? "/about",
    meta: indexC7lJSFUqWQMeta || {},
    alias: indexC7lJSFUqWQMeta?.alias || [],
    redirect: indexC7lJSFUqWQMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexHyvHwFqkwcMeta?.name ?? "branches",
    path: indexHyvHwFqkwcMeta?.path ?? "/branches",
    meta: indexHyvHwFqkwcMeta || {},
    alias: indexHyvHwFqkwcMeta?.alias || [],
    redirect: indexHyvHwFqkwcMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/branches/index.vue").then(m => m.default || m)
  },
  {
    name: index8uMWiaPHC2Meta?.name ?? "cancel",
    path: index8uMWiaPHC2Meta?.path ?? "/cancel",
    meta: index8uMWiaPHC2Meta || {},
    alias: index8uMWiaPHC2Meta?.alias || [],
    redirect: index8uMWiaPHC2Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: index3HUeaTum7xMeta?.name ?? "career",
    path: index3HUeaTum7xMeta?.path ?? "/career",
    meta: index3HUeaTum7xMeta || {},
    alias: index3HUeaTum7xMeta?.alias || [],
    redirect: index3HUeaTum7xMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: FaqslgyZB8xqQdMeta?.name ?? "cartrust-agent-components-Faqs",
    path: FaqslgyZB8xqQdMeta?.path ?? "/cartrust-agent/components/Faqs",
    meta: FaqslgyZB8xqQdMeta || {},
    alias: FaqslgyZB8xqQdMeta?.alias || [],
    redirect: FaqslgyZB8xqQdMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Faqs.vue").then(m => m.default || m)
  },
  {
    name: FeaturesglcAXYFsaNMeta?.name ?? "cartrust-agent-components-Features",
    path: FeaturesglcAXYFsaNMeta?.path ?? "/cartrust-agent/components/Features",
    meta: FeaturesglcAXYFsaNMeta || {},
    alias: FeaturesglcAXYFsaNMeta?.alias || [],
    redirect: FeaturesglcAXYFsaNMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Features.vue").then(m => m.default || m)
  },
  {
    name: OpportunitiesmAy7ovvNHIMeta?.name ?? "cartrust-agent-components-Opportunities",
    path: OpportunitiesmAy7ovvNHIMeta?.path ?? "/cartrust-agent/components/Opportunities",
    meta: OpportunitiesmAy7ovvNHIMeta || {},
    alias: OpportunitiesmAy7ovvNHIMeta?.alias || [],
    redirect: OpportunitiesmAy7ovvNHIMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Opportunities.vue").then(m => m.default || m)
  },
  {
    name: PlanComparisonPUSnlWlITqMeta?.name ?? "cartrust-agent-components-PlanComparison",
    path: PlanComparisonPUSnlWlITqMeta?.path ?? "/cartrust-agent/components/PlanComparison",
    meta: PlanComparisonPUSnlWlITqMeta || {},
    alias: PlanComparisonPUSnlWlITqMeta?.alias || [],
    redirect: PlanComparisonPUSnlWlITqMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/PlanComparison.vue").then(m => m.default || m)
  },
  {
    name: PricingJmCJoZiUfxMeta?.name ?? "cartrust-agent-components-Pricing",
    path: PricingJmCJoZiUfxMeta?.path ?? "/cartrust-agent/components/Pricing",
    meta: PricingJmCJoZiUfxMeta || {},
    alias: PricingJmCJoZiUfxMeta?.alias || [],
    redirect: PricingJmCJoZiUfxMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Pricing.vue").then(m => m.default || m)
  },
  {
    name: Saas3HerokTg0RpPwxBMeta?.name ?? "cartrust-agent-components-Saas3Hero",
    path: Saas3HerokTg0RpPwxBMeta?.path ?? "/cartrust-agent/components/Saas3Hero",
    meta: Saas3HerokTg0RpPwxBMeta || {},
    alias: Saas3HerokTg0RpPwxBMeta?.alias || [],
    redirect: Saas3HerokTg0RpPwxBMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Saas3Hero.vue").then(m => m.default || m)
  },
  {
    name: TestimonialzVFms86If2Meta?.name ?? "cartrust-agent-components-Testimonial",
    path: TestimonialzVFms86If2Meta?.path ?? "/cartrust-agent/components/Testimonial",
    meta: TestimonialzVFms86If2Meta || {},
    alias: TestimonialzVFms86If2Meta?.alias || [],
    redirect: TestimonialzVFms86If2Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardvtJPhjsHVhMeta?.name ?? "cartrust-agent-components-TestimonialCard",
    path: TestimonialCardvtJPhjsHVhMeta?.path ?? "/cartrust-agent/components/TestimonialCard",
    meta: TestimonialCardvtJPhjsHVhMeta || {},
    alias: TestimonialCardvtJPhjsHVhMeta?.alias || [],
    redirect: TestimonialCardvtJPhjsHVhMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: WorkNqpLdazYueMeta?.name ?? "cartrust-agent-components-Work",
    path: WorkNqpLdazYueMeta?.path ?? "/cartrust-agent/components/Work",
    meta: WorkNqpLdazYueMeta || {},
    alias: WorkNqpLdazYueMeta?.alias || [],
    redirect: WorkNqpLdazYueMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/components/Work.vue").then(m => m.default || m)
  },
  {
    name: datagXWV9yrX6OMeta?.name ?? "cartrust-agent-data",
    path: datagXWV9yrX6OMeta?.path ?? "/cartrust-agent/data",
    meta: datagXWV9yrX6OMeta || {},
    alias: datagXWV9yrX6OMeta?.alias || [],
    redirect: datagXWV9yrX6OMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/data.ts").then(m => m.default || m)
  },
  {
    name: indexT01z0P01H1Meta?.name ?? "cartrust-agent",
    path: indexT01z0P01H1Meta?.path ?? "/cartrust-agent",
    meta: indexT01z0P01H1Meta || {},
    alias: indexT01z0P01H1Meta?.alias || [],
    redirect: indexT01z0P01H1Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/cartrust-agent/index.vue").then(m => m.default || m)
  },
  {
    name: indexgpOtpHs9NfMeta?.name ?? "checkout",
    path: indexgpOtpHs9NfMeta?.path ?? "/checkout",
    meta: indexgpOtpHs9NfMeta || {},
    alias: indexgpOtpHs9NfMeta?.alias || [],
    redirect: indexgpOtpHs9NfMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: Contacts1FooteruFH9ofTMdHMeta?.name ?? "contact-components-Contacts1Footer",
    path: Contacts1FooteruFH9ofTMdHMeta?.path ?? "/contact/components/Contacts1Footer",
    meta: Contacts1FooteruFH9ofTMdHMeta || {},
    alias: Contacts1FooteruFH9ofTMdHMeta?.alias || [],
    redirect: Contacts1FooteruFH9ofTMdHMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Contacts1Footer.vue").then(m => m.default || m)
  },
  {
    name: Contacts1HeroGsgNebMwS5Meta?.name ?? "contact-components-Contacts1Hero",
    path: Contacts1HeroGsgNebMwS5Meta?.path ?? "/contact/components/Contacts1Hero",
    meta: Contacts1HeroGsgNebMwS5Meta || {},
    alias: Contacts1HeroGsgNebMwS5Meta?.alias || [],
    redirect: Contacts1HeroGsgNebMwS5Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Contacts1Hero.vue").then(m => m.default || m)
  },
  {
    name: DetailsEZcW0DUtaBMeta?.name ?? "contact-components-Details",
    path: DetailsEZcW0DUtaBMeta?.path ?? "/contact/components/Details",
    meta: DetailsEZcW0DUtaBMeta || {},
    alias: DetailsEZcW0DUtaBMeta?.alias || [],
    redirect: DetailsEZcW0DUtaBMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Details.vue").then(m => m.default || m)
  },
  {
    name: Faqs1H40WnSjRRMeta?.name ?? "contact-components-Faqs",
    path: Faqs1H40WnSjRRMeta?.path ?? "/contact/components/Faqs",
    meta: Faqs1H40WnSjRRMeta || {},
    alias: Faqs1H40WnSjRRMeta?.alias || [],
    redirect: Faqs1H40WnSjRRMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/components/Faqs.vue").then(m => m.default || m)
  },
  {
    name: dataVvZz6TX0wLMeta?.name ?? "contact-data",
    path: dataVvZz6TX0wLMeta?.path ?? "/contact/data",
    meta: dataVvZz6TX0wLMeta || {},
    alias: dataVvZz6TX0wLMeta?.alias || [],
    redirect: dataVvZz6TX0wLMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/data.ts").then(m => m.default || m)
  },
  {
    name: indexWSxAutHKlQMeta?.name ?? "contact",
    path: indexWSxAutHKlQMeta?.path ?? "/contact",
    meta: indexWSxAutHKlQMeta || {},
    alias: indexWSxAutHKlQMeta?.alias || [],
    redirect: indexWSxAutHKlQMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: index3DSExDRhtfMeta?.name ?? "dashboard-coupons",
    path: index3DSExDRhtfMeta?.path ?? "/dashboard/coupons",
    meta: index3DSExDRhtfMeta || {},
    alias: index3DSExDRhtfMeta?.alias || [],
    redirect: index3DSExDRhtfMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: indexBhK8qzFTYSMeta?.name ?? "dashboard",
    path: indexBhK8qzFTYSMeta?.path ?? "/dashboard",
    meta: indexBhK8qzFTYSMeta || {},
    alias: indexBhK8qzFTYSMeta?.alias || [],
    redirect: indexBhK8qzFTYSMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: inspectionsax7r6HsLuFMeta?.name ?? "dashboard-inspections",
    path: inspectionsax7r6HsLuFMeta?.path ?? "/dashboard/inspections",
    meta: inspectionsax7r6HsLuFMeta || {},
    alias: inspectionsax7r6HsLuFMeta?.alias || [],
    redirect: inspectionsax7r6HsLuFMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/inspections.vue").then(m => m.default || m)
  },
  {
    name: indexNe0FXb7uXoMeta?.name ?? "dashboard-profile",
    path: indexNe0FXb7uXoMeta?.path ?? "/dashboard/profile",
    meta: indexNe0FXb7uXoMeta || {},
    alias: indexNe0FXb7uXoMeta?.alias || [],
    redirect: indexNe0FXb7uXoMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: transaction40m0j1fqDaMeta?.name ?? "dashboard-transaction",
    path: transaction40m0j1fqDaMeta?.path ?? "/dashboard/transaction",
    meta: transaction40m0j1fqDaMeta || {},
    alias: transaction40m0j1fqDaMeta?.alias || [],
    redirect: transaction40m0j1fqDaMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/transaction.vue").then(m => m.default || m)
  },
  {
    name: index04Htf83BVQMeta?.name ?? "dashboard-user-scan-list",
    path: index04Htf83BVQMeta?.path ?? "/dashboard/user-scan-list",
    meta: index04Htf83BVQMeta || {},
    alias: index04Htf83BVQMeta?.alias || [],
    redirect: index04Htf83BVQMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/user-scan-list/index.vue").then(m => m.default || m)
  },
  {
    name: vehiclesf59Gv3RCTNMeta?.name ?? "dashboard-vehicles",
    path: vehiclesf59Gv3RCTNMeta?.path ?? "/dashboard/vehicles",
    meta: vehiclesf59Gv3RCTNMeta || {},
    alias: vehiclesf59Gv3RCTNMeta?.alias || [],
    redirect: vehiclesf59Gv3RCTNMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/vehicles.vue").then(m => m.default || m)
  },
  {
    name: wallethw7MIK1VJxMeta?.name ?? "dashboard-wallet",
    path: wallethw7MIK1VJxMeta?.path ?? "/dashboard/wallet",
    meta: wallethw7MIK1VJxMeta || {},
    alias: wallethw7MIK1VJxMeta?.alias || [],
    redirect: wallethw7MIK1VJxMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/wallet.vue").then(m => m.default || m)
  },
  {
    name: warrantiesqXEdXgE6EEMeta?.name ?? "dashboard-warranties",
    path: warrantiesqXEdXgE6EEMeta?.path ?? "/dashboard/warranties",
    meta: warrantiesqXEdXgE6EEMeta || {},
    alias: warrantiesqXEdXgE6EEMeta?.alias || [],
    redirect: warrantiesqXEdXgE6EEMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/dashboard/warranties.vue").then(m => m.default || m)
  },
  {
    name: ContentIbmj4e8jjtMeta?.name ?? "data-products-disclaimer-components-Content",
    path: ContentIbmj4e8jjtMeta?.path ?? "/data-products-disclaimer/components/Content",
    meta: ContentIbmj4e8jjtMeta || {},
    alias: ContentIbmj4e8jjtMeta?.alias || [],
    redirect: ContentIbmj4e8jjtMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/data-products-disclaimer/components/Content.vue").then(m => m.default || m)
  },
  {
    name: indexk4Rrq9MgolMeta?.name ?? "data-products-disclaimer",
    path: indexk4Rrq9MgolMeta?.path ?? "/data-products-disclaimer",
    meta: indexk4Rrq9MgolMeta || {},
    alias: indexk4Rrq9MgolMeta?.alias || [],
    redirect: indexk4Rrq9MgolMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/data-products-disclaimer/index.vue").then(m => m.default || m)
  },
  {
    name: BrandsE3HDFtp4gxMeta?.name ?? "evaluation-components-Brands",
    path: BrandsE3HDFtp4gxMeta?.path ?? "/evaluation/components/Brands",
    meta: BrandsE3HDFtp4gxMeta || {},
    alias: BrandsE3HDFtp4gxMeta?.alias || [],
    redirect: BrandsE3HDFtp4gxMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Brands.vue").then(m => m.default || m)
  },
  {
    name: CarSearchBoxeFOEfAt6eLMeta?.name ?? "evaluation-components-CarSearchBox",
    path: CarSearchBoxeFOEfAt6eLMeta?.path ?? "/evaluation/components/CarSearchBox",
    meta: CarSearchBoxeFOEfAt6eLMeta || {},
    alias: CarSearchBoxeFOEfAt6eLMeta?.alias || [],
    redirect: CarSearchBoxeFOEfAt6eLMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/CarSearchBox.vue").then(m => m.default || m)
  },
  {
    name: ContactjEirgnPWmiMeta?.name ?? "evaluation-components-Contact",
    path: ContactjEirgnPWmiMeta?.path ?? "/evaluation/components/Contact",
    meta: ContactjEirgnPWmiMeta || {},
    alias: ContactjEirgnPWmiMeta?.alias || [],
    redirect: ContactjEirgnPWmiMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: CovidBannerUUjHs31wYeMeta?.name ?? "evaluation-components-CovidBanner",
    path: CovidBannerUUjHs31wYeMeta?.path ?? "/evaluation/components/CovidBanner",
    meta: CovidBannerUUjHs31wYeMeta || {},
    alias: CovidBannerUUjHs31wYeMeta?.alias || [],
    redirect: CovidBannerUUjHs31wYeMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/CovidBanner.vue").then(m => m.default || m)
  },
  {
    name: FeaturewO4RJaNtnTMeta?.name ?? "evaluation-components-Feature",
    path: FeaturewO4RJaNtnTMeta?.path ?? "/evaluation/components/Feature",
    meta: FeaturewO4RJaNtnTMeta || {},
    alias: FeaturewO4RJaNtnTMeta?.alias || [],
    redirect: FeaturewO4RJaNtnTMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Feature.vue").then(m => m.default || m)
  },
  {
    name: FinancialFooterTHGbsr5fceMeta?.name ?? "evaluation-components-FinancialFooter",
    path: FinancialFooterTHGbsr5fceMeta?.path ?? "/evaluation/components/FinancialFooter",
    meta: FinancialFooterTHGbsr5fceMeta || {},
    alias: FinancialFooterTHGbsr5fceMeta?.alias || [],
    redirect: FinancialFooterTHGbsr5fceMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/FinancialFooter.vue").then(m => m.default || m)
  },
  {
    name: FinancialHeroftb52wvPx5Meta?.name ?? "evaluation-components-FinancialHero",
    path: FinancialHeroftb52wvPx5Meta?.path ?? "/evaluation/components/FinancialHero",
    meta: FinancialHeroftb52wvPx5Meta || {},
    alias: FinancialHeroftb52wvPx5Meta?.alias || [],
    redirect: FinancialHeroftb52wvPx5Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/FinancialHero.vue").then(m => m.default || m)
  },
  {
    name: IndustrieSwipern1uaIjJJu7Meta?.name ?? "evaluation-components-IndustrieSwiper",
    path: IndustrieSwipern1uaIjJJu7Meta?.path ?? "/evaluation/components/IndustrieSwiper",
    meta: IndustrieSwipern1uaIjJJu7Meta || {},
    alias: IndustrieSwipern1uaIjJJu7Meta?.alias || [],
    redirect: IndustrieSwipern1uaIjJJu7Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/IndustrieSwiper.vue").then(m => m.default || m)
  },
  {
    name: NewsCardKVDYlwiG7qMeta?.name ?? "evaluation-components-NewsCard",
    path: NewsCardKVDYlwiG7qMeta?.path ?? "/evaluation/components/NewsCard",
    meta: NewsCardKVDYlwiG7qMeta || {},
    alias: NewsCardKVDYlwiG7qMeta?.alias || [],
    redirect: NewsCardKVDYlwiG7qMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/NewsCard.vue").then(m => m.default || m)
  },
  {
    name: NewsSwiper5vdRF5rvHwMeta?.name ?? "evaluation-components-NewsSwiper",
    path: NewsSwiper5vdRF5rvHwMeta?.path ?? "/evaluation/components/NewsSwiper",
    meta: NewsSwiper5vdRF5rvHwMeta || {},
    alias: NewsSwiper5vdRF5rvHwMeta?.alias || [],
    redirect: NewsSwiper5vdRF5rvHwMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/NewsSwiper.vue").then(m => m.default || m)
  },
  {
    name: PricingqVlObOwvYjMeta?.name ?? "evaluation-components-Pricing",
    path: PricingqVlObOwvYjMeta?.path ?? "/evaluation/components/Pricing",
    meta: PricingqVlObOwvYjMeta || {},
    alias: PricingqVlObOwvYjMeta?.alias || [],
    redirect: PricingqVlObOwvYjMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Pricing.vue").then(m => m.default || m)
  },
  {
    name: PurchaseLRKDh9wMiqMeta?.name ?? "evaluation-components-Purchase",
    path: PurchaseLRKDh9wMiqMeta?.path ?? "/evaluation/components/Purchase",
    meta: PurchaseLRKDh9wMiqMeta || {},
    alias: PurchaseLRKDh9wMiqMeta?.alias || [],
    redirect: PurchaseLRKDh9wMiqMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Purchase.vue").then(m => m.default || m)
  },
  {
    name: ServicesiWv1gSmE5jMeta?.name ?? "evaluation-components-Services",
    path: ServicesiWv1gSmE5jMeta?.path ?? "/evaluation/components/Services",
    meta: ServicesiWv1gSmE5jMeta || {},
    alias: ServicesiWv1gSmE5jMeta?.alias || [],
    redirect: ServicesiWv1gSmE5jMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Services.vue").then(m => m.default || m)
  },
  {
    name: Sponsorsxnb4i7JQVkMeta?.name ?? "evaluation-components-Sponsors",
    path: Sponsorsxnb4i7JQVkMeta?.path ?? "/evaluation/components/Sponsors",
    meta: Sponsorsxnb4i7JQVkMeta || {},
    alias: Sponsorsxnb4i7JQVkMeta?.alias || [],
    redirect: Sponsorsxnb4i7JQVkMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Sponsors.vue").then(m => m.default || m)
  },
  {
    name: TestimonialEIlILcU2cyMeta?.name ?? "evaluation-components-Testimonial",
    path: TestimonialEIlILcU2cyMeta?.path ?? "/evaluation/components/Testimonial",
    meta: TestimonialEIlILcU2cyMeta || {},
    alias: TestimonialEIlILcU2cyMeta?.alias || [],
    redirect: TestimonialEIlILcU2cyMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardwhqGROy9xRMeta?.name ?? "evaluation-components-TestimonialCard",
    path: TestimonialCardwhqGROy9xRMeta?.path ?? "/evaluation/components/TestimonialCard",
    meta: TestimonialCardwhqGROy9xRMeta || {},
    alias: TestimonialCardwhqGROy9xRMeta?.alias || [],
    redirect: TestimonialCardwhqGROy9xRMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: dataot4kZ4WkPkMeta?.name ?? "evaluation-data",
    path: dataot4kZ4WkPkMeta?.path ?? "/evaluation/data",
    meta: dataot4kZ4WkPkMeta || {},
    alias: dataot4kZ4WkPkMeta?.alias || [],
    redirect: dataot4kZ4WkPkMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/data.ts").then(m => m.default || m)
  },
  {
    name: indexlJ2D6PeADRMeta?.name ?? "evaluation",
    path: indexlJ2D6PeADRMeta?.path ?? "/evaluation",
    meta: indexlJ2D6PeADRMeta || {},
    alias: indexlJ2D6PeADRMeta?.alias || [],
    redirect: indexlJ2D6PeADRMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluation/index.vue").then(m => m.default || m)
  },
  {
    name: indexqujjdqHaiLMeta?.name ?? "evaluations-id",
    path: indexqujjdqHaiLMeta?.path ?? "/evaluations/:id()",
    meta: indexqujjdqHaiLMeta || {},
    alias: indexqujjdqHaiLMeta?.alias || [],
    redirect: indexqujjdqHaiLMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: feedbacks3goOTqpDwMeta?.name ?? "evaluations-Components-feedback",
    path: feedbacks3goOTqpDwMeta?.path ?? "/evaluations/Components/feedback",
    meta: feedbacks3goOTqpDwMeta || {},
    alias: feedbacks3goOTqpDwMeta?.alias || [],
    redirect: feedbacks3goOTqpDwMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/Components/feedback.vue").then(m => m.default || m)
  },
  {
    name: ReEvaluation2iuxCYIpg1Meta?.name ?? "evaluations-Components-ReEvaluation",
    path: ReEvaluation2iuxCYIpg1Meta?.path ?? "/evaluations/Components/ReEvaluation",
    meta: ReEvaluation2iuxCYIpg1Meta || {},
    alias: ReEvaluation2iuxCYIpg1Meta?.alias || [],
    redirect: ReEvaluation2iuxCYIpg1Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/Components/ReEvaluation.vue").then(m => m.default || m)
  },
  {
    name: indexhhLA8wrlkdMeta?.name ?? "evaluations",
    path: indexhhLA8wrlkdMeta?.path ?? "/evaluations",
    meta: indexhhLA8wrlkdMeta || {},
    alias: indexhhLA8wrlkdMeta?.alias || [],
    redirect: indexhhLA8wrlkdMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/evaluations/index.vue").then(m => m.default || m)
  },
  {
    name: indexGVsyr8TbR2Meta?.name ?? "fail",
    path: indexGVsyr8TbR2Meta?.path ?? "/fail",
    meta: indexGVsyr8TbR2Meta || {},
    alias: indexGVsyr8TbR2Meta?.alias || [],
    redirect: indexGVsyr8TbR2Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/fail/index.vue").then(m => m.default || m)
  },
  {
    name: Contacts1HerotGQjqT6f3JMeta?.name ?? "faq-components-Contacts1Hero",
    path: Contacts1HerotGQjqT6f3JMeta?.path ?? "/faq/components/Contacts1Hero",
    meta: Contacts1HerotGQjqT6f3JMeta || {},
    alias: Contacts1HerotGQjqT6f3JMeta?.alias || [],
    redirect: Contacts1HerotGQjqT6f3JMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/components/Contacts1Hero.vue").then(m => m.default || m)
  },
  {
    name: DetailscEZrsjjEeVMeta?.name ?? "faq-components-Details",
    path: DetailscEZrsjjEeVMeta?.path ?? "/faq/components/Details",
    meta: DetailscEZrsjjEeVMeta || {},
    alias: DetailscEZrsjjEeVMeta?.alias || [],
    redirect: DetailscEZrsjjEeVMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/components/Details.vue").then(m => m.default || m)
  },
  {
    name: FaqsbZdOZoIFgRMeta?.name ?? "faq-components-Faqs",
    path: FaqsbZdOZoIFgRMeta?.path ?? "/faq/components/Faqs",
    meta: FaqsbZdOZoIFgRMeta || {},
    alias: FaqsbZdOZoIFgRMeta?.alias || [],
    redirect: FaqsbZdOZoIFgRMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/components/Faqs.vue").then(m => m.default || m)
  },
  {
    name: datafjik97vmKCMeta?.name ?? "faq-data",
    path: datafjik97vmKCMeta?.path ?? "/faq/data",
    meta: datafjik97vmKCMeta || {},
    alias: datafjik97vmKCMeta?.alias || [],
    redirect: datafjik97vmKCMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/data.ts").then(m => m.default || m)
  },
  {
    name: indexMt4hGxByKEMeta?.name ?? "faq",
    path: indexMt4hGxByKEMeta?.path ?? "/faq",
    meta: indexMt4hGxByKEMeta || {},
    alias: indexMt4hGxByKEMeta?.alias || [],
    redirect: indexMt4hGxByKEMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: HeroSectionsqJpDPFKaSMeta?.name ?? "help-me-by-a-car-components-HeroSection",
    path: HeroSectionsqJpDPFKaSMeta?.path ?? "/help-me-by-a-car/components/HeroSection",
    meta: HeroSectionsqJpDPFKaSMeta || {},
    alias: HeroSectionsqJpDPFKaSMeta?.alias || [],
    redirect: HeroSectionsqJpDPFKaSMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/components/HeroSection.vue").then(m => m.default || m)
  },
  {
    name: SearchCarKpTxLig0ffMeta?.name ?? "help-me-by-a-car-components-SearchCar",
    path: SearchCarKpTxLig0ffMeta?.path ?? "/help-me-by-a-car/components/SearchCar",
    meta: SearchCarKpTxLig0ffMeta || {},
    alias: SearchCarKpTxLig0ffMeta?.alias || [],
    redirect: SearchCarKpTxLig0ffMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/components/SearchCar.vue").then(m => m.default || m)
  },
  {
    name: SpecificationstGB3eAUOheMeta?.name ?? "help-me-by-a-car-components-Specifications",
    path: SpecificationstGB3eAUOheMeta?.path ?? "/help-me-by-a-car/components/Specifications",
    meta: SpecificationstGB3eAUOheMeta || {},
    alias: SpecificationstGB3eAUOheMeta?.alias || [],
    redirect: SpecificationstGB3eAUOheMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/components/Specifications.vue").then(m => m.default || m)
  },
  {
    name: indexNHSSNO33vhMeta?.name ?? "help-me-by-a-car",
    path: indexNHSSNO33vhMeta?.path ?? "/help-me-by-a-car",
    meta: indexNHSSNO33vhMeta || {},
    alias: indexNHSSNO33vhMeta?.alias || [],
    redirect: indexNHSSNO33vhMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/help-me-by-a-car/index.vue").then(m => m.default || m)
  },
  {
    name: ContentZpRitQiirdMeta?.name ?? "impartiality-policy-components-Content",
    path: ContentZpRitQiirdMeta?.path ?? "/impartiality-policy/components/Content",
    meta: ContentZpRitQiirdMeta || {},
    alias: ContentZpRitQiirdMeta?.alias || [],
    redirect: ContentZpRitQiirdMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/impartiality-policy/components/Content.vue").then(m => m.default || m)
  },
  {
    name: indexmqvX87d7e1Meta?.name ?? "impartiality-policy",
    path: indexmqvX87d7e1Meta?.path ?? "/impartiality-policy",
    meta: indexmqvX87d7e1Meta || {},
    alias: indexmqvX87d7e1Meta?.alias || [],
    redirect: indexmqvX87d7e1Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/impartiality-policy/index.vue").then(m => m.default || m)
  },
  {
    name: index0vTwMH94WEMeta?.name ?? "index",
    path: index0vTwMH94WEMeta?.path ?? "/",
    meta: index0vTwMH94WEMeta || {},
    alias: index0vTwMH94WEMeta?.alias || [],
    redirect: index0vTwMH94WEMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: CarSearchBoxKuC15rqcXtMeta?.name ?? "inspection-components-CarSearchBox",
    path: CarSearchBoxKuC15rqcXtMeta?.path ?? "/inspection/components/CarSearchBox",
    meta: CarSearchBoxKuC15rqcXtMeta || {},
    alias: CarSearchBoxKuC15rqcXtMeta?.alias || [],
    redirect: CarSearchBoxKuC15rqcXtMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/CarSearchBox.vue").then(m => m.default || m)
  },
  {
    name: ComparisonSlider86QyZkqDpfMeta?.name ?? "inspection-components-ComparisonSlider",
    path: ComparisonSlider86QyZkqDpfMeta?.path ?? "/inspection/components/ComparisonSlider",
    meta: ComparisonSlider86QyZkqDpfMeta || {},
    alias: ComparisonSlider86QyZkqDpfMeta?.alias || [],
    redirect: ComparisonSlider86QyZkqDpfMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/ComparisonSlider.vue").then(m => m.default || m)
  },
  {
    name: CtaCGNn1GpHCbMeta?.name ?? "inspection-components-Cta",
    path: CtaCGNn1GpHCbMeta?.path ?? "/inspection/components/Cta",
    meta: CtaCGNn1GpHCbMeta || {},
    alias: CtaCGNn1GpHCbMeta?.alias || [],
    redirect: CtaCGNn1GpHCbMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Cta.vue").then(m => m.default || m)
  },
  {
    name: FeaturesvCKIAd5VQfMeta?.name ?? "inspection-components-Features",
    path: FeaturesvCKIAd5VQfMeta?.path ?? "/inspection/components/Features",
    meta: FeaturesvCKIAd5VQfMeta || {},
    alias: FeaturesvCKIAd5VQfMeta?.alias || [],
    redirect: FeaturesvCKIAd5VQfMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Features.vue").then(m => m.default || m)
  },
  {
    name: Features2F14G9JUm2OMeta?.name ?? "inspection-components-Features2",
    path: Features2F14G9JUm2OMeta?.path ?? "/inspection/components/Features2",
    meta: Features2F14G9JUm2OMeta || {},
    alias: Features2F14G9JUm2OMeta?.alias || [],
    redirect: Features2F14G9JUm2OMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Features2.vue").then(m => m.default || m)
  },
  {
    name: InspectionTypesDDNF2YogiNMeta?.name ?? "inspection-components-InspectionTypes",
    path: InspectionTypesDDNF2YogiNMeta?.path ?? "/inspection/components/InspectionTypes",
    meta: InspectionTypesDDNF2YogiNMeta || {},
    alias: InspectionTypesDDNF2YogiNMeta?.alias || [],
    redirect: InspectionTypesDDNF2YogiNMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/InspectionTypes.vue").then(m => m.default || m)
  },
  {
    name: Integrationsm28JwWStlQMeta?.name ?? "inspection-components-Integrations",
    path: Integrationsm28JwWStlQMeta?.path ?? "/inspection/components/Integrations",
    meta: Integrationsm28JwWStlQMeta || {},
    alias: Integrationsm28JwWStlQMeta?.alias || [],
    redirect: Integrationsm28JwWStlQMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Integrations.vue").then(m => m.default || m)
  },
  {
    name: Saas1FooteruuA2cfDwl4Meta?.name ?? "inspection-components-Saas1Footer",
    path: Saas1FooteruuA2cfDwl4Meta?.path ?? "/inspection/components/Saas1Footer",
    meta: Saas1FooteruuA2cfDwl4Meta || {},
    alias: Saas1FooteruuA2cfDwl4Meta?.alias || [],
    redirect: Saas1FooteruuA2cfDwl4Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Saas1Footer.vue").then(m => m.default || m)
  },
  {
    name: Saas1Hero7mWRvceOT5Meta?.name ?? "inspection-components-Saas1Hero",
    path: Saas1Hero7mWRvceOT5Meta?.path ?? "/inspection/components/Saas1Hero",
    meta: Saas1Hero7mWRvceOT5Meta || {},
    alias: Saas1Hero7mWRvceOT5Meta?.alias || [],
    redirect: Saas1Hero7mWRvceOT5Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Saas1Hero.vue").then(m => m.default || m)
  },
  {
    name: Testimonialk1VfMWzHCxMeta?.name ?? "inspection-components-Testimonial",
    path: Testimonialk1VfMWzHCxMeta?.path ?? "/inspection/components/Testimonial",
    meta: Testimonialk1VfMWzHCxMeta || {},
    alias: Testimonialk1VfMWzHCxMeta?.alias || [],
    redirect: Testimonialk1VfMWzHCxMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardpQsyFpskDcMeta?.name ?? "inspection-components-TestimonialCard",
    path: TestimonialCardpQsyFpskDcMeta?.path ?? "/inspection/components/TestimonialCard",
    meta: TestimonialCardpQsyFpskDcMeta || {},
    alias: TestimonialCardpQsyFpskDcMeta?.alias || [],
    redirect: TestimonialCardpQsyFpskDcMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: UseCaseZJ4hrvx3TjMeta?.name ?? "inspection-components-UseCase",
    path: UseCaseZJ4hrvx3TjMeta?.path ?? "/inspection/components/UseCase",
    meta: UseCaseZJ4hrvx3TjMeta || {},
    alias: UseCaseZJ4hrvx3TjMeta?.alias || [],
    redirect: UseCaseZJ4hrvx3TjMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/UseCase.vue").then(m => m.default || m)
  },
  {
    name: WorkolTVOojAXiMeta?.name ?? "inspection-components-Work",
    path: WorkolTVOojAXiMeta?.path ?? "/inspection/components/Work",
    meta: WorkolTVOojAXiMeta || {},
    alias: WorkolTVOojAXiMeta?.alias || [],
    redirect: WorkolTVOojAXiMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/components/Work.vue").then(m => m.default || m)
  },
  {
    name: datagwX0SJwdPOMeta?.name ?? "inspection-data",
    path: datagwX0SJwdPOMeta?.path ?? "/inspection/data",
    meta: datagwX0SJwdPOMeta || {},
    alias: datagwX0SJwdPOMeta?.alias || [],
    redirect: datagwX0SJwdPOMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/data.ts").then(m => m.default || m)
  },
  {
    name: indexIy1AtPIKcIMeta?.name ?? "inspection",
    path: indexIy1AtPIKcIMeta?.path ?? "/inspection",
    meta: indexIy1AtPIKcIMeta || {},
    alias: indexIy1AtPIKcIMeta?.alias || [],
    redirect: indexIy1AtPIKcIMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspection/index.vue").then(m => m.default || m)
  },
  {
    name: indexeS6vly4KAPMeta?.name ?? "inspections-id",
    path: indexeS6vly4KAPMeta?.path ?? "/inspections/:id()",
    meta: indexeS6vly4KAPMeta || {},
    alias: indexeS6vly4KAPMeta?.alias || [],
    redirect: indexeS6vly4KAPMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspections/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGuB7zTzvlDMeta?.name ?? "inspections",
    path: indexGuB7zTzvlDMeta?.path ?? "/inspections",
    meta: indexGuB7zTzvlDMeta || {},
    alias: indexGuB7zTzvlDMeta?.alias || [],
    redirect: indexGuB7zTzvlDMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/inspections/index.vue").then(m => m.default || m)
  },
  {
    name: image504kY5oLtjMeta?.name ?? "landing-mobile-app-1-assets-data-image",
    path: image504kY5oLtjMeta?.path ?? "/landing/mobile-app-1/assets/data/image",
    meta: image504kY5oLtjMeta || {},
    alias: image504kY5oLtjMeta?.alias || [],
    redirect: image504kY5oLtjMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/assets/data/image.ts").then(m => m.default || m)
  },
  {
    name: BrandsvsGSVmB1fuMeta?.name ?? "landing-mobile-app-1-components-Brands",
    path: BrandsvsGSVmB1fuMeta?.path ?? "/landing/mobile-app-1/components/Brands",
    meta: BrandsvsGSVmB1fuMeta || {},
    alias: BrandsvsGSVmB1fuMeta?.alias || [],
    redirect: BrandsvsGSVmB1fuMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Brands.vue").then(m => m.default || m)
  },
  {
    name: Cta2UWHUiCHMFMeta?.name ?? "landing-mobile-app-1-components-Cta",
    path: Cta2UWHUiCHMFMeta?.path ?? "/landing/mobile-app-1/components/Cta",
    meta: Cta2UWHUiCHMFMeta || {},
    alias: Cta2UWHUiCHMFMeta?.alias || [],
    redirect: Cta2UWHUiCHMFMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Cta.vue").then(m => m.default || m)
  },
  {
    name: FaqsosVxWYfvpmMeta?.name ?? "landing-mobile-app-1-components-Faqs",
    path: FaqsosVxWYfvpmMeta?.path ?? "/landing/mobile-app-1/components/Faqs",
    meta: FaqsosVxWYfvpmMeta || {},
    alias: FaqsosVxWYfvpmMeta?.alias || [],
    redirect: FaqsosVxWYfvpmMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Faqs.vue").then(m => m.default || m)
  },
  {
    name: Features1bCXGEbvQ7DMeta?.name ?? "landing-mobile-app-1-components-Features1",
    path: Features1bCXGEbvQ7DMeta?.path ?? "/landing/mobile-app-1/components/Features1",
    meta: Features1bCXGEbvQ7DMeta || {},
    alias: Features1bCXGEbvQ7DMeta?.alias || [],
    redirect: Features1bCXGEbvQ7DMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Features1.vue").then(m => m.default || m)
  },
  {
    name: Features26eU8dIInJNMeta?.name ?? "landing-mobile-app-1-components-Features2",
    path: Features26eU8dIInJNMeta?.path ?? "/landing/mobile-app-1/components/Features2",
    meta: Features26eU8dIInJNMeta || {},
    alias: Features26eU8dIInJNMeta?.alias || [],
    redirect: Features26eU8dIInJNMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Features2.vue").then(m => m.default || m)
  },
  {
    name: MobileApp1FooterUJaUbWV3AgMeta?.name ?? "landing-mobile-app-1-components-MobileApp1Footer",
    path: MobileApp1FooterUJaUbWV3AgMeta?.path ?? "/landing/mobile-app-1/components/MobileApp1Footer",
    meta: MobileApp1FooterUJaUbWV3AgMeta || {},
    alias: MobileApp1FooterUJaUbWV3AgMeta?.alias || [],
    redirect: MobileApp1FooterUJaUbWV3AgMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/MobileApp1Footer.vue").then(m => m.default || m)
  },
  {
    name: MobileApp1HerobXWsvZcayvMeta?.name ?? "landing-mobile-app-1-components-MobileApp1Hero",
    path: MobileApp1HerobXWsvZcayvMeta?.path ?? "/landing/mobile-app-1/components/MobileApp1Hero",
    meta: MobileApp1HerobXWsvZcayvMeta || {},
    alias: MobileApp1HerobXWsvZcayvMeta?.alias || [],
    redirect: MobileApp1HerobXWsvZcayvMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/MobileApp1Hero.vue").then(m => m.default || m)
  },
  {
    name: ProcessSwiperKJxRFJQUlmMeta?.name ?? "landing-mobile-app-1-components-ProcessSwiper",
    path: ProcessSwiperKJxRFJQUlmMeta?.path ?? "/landing/mobile-app-1/components/ProcessSwiper",
    meta: ProcessSwiperKJxRFJQUlmMeta || {},
    alias: ProcessSwiperKJxRFJQUlmMeta?.alias || [],
    redirect: ProcessSwiperKJxRFJQUlmMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/ProcessSwiper.vue").then(m => m.default || m)
  },
  {
    name: TestimonialaQSbcI0K9rMeta?.name ?? "landing-mobile-app-1-components-Testimonial",
    path: TestimonialaQSbcI0K9rMeta?.path ?? "/landing/mobile-app-1/components/Testimonial",
    meta: TestimonialaQSbcI0K9rMeta || {},
    alias: TestimonialaQSbcI0K9rMeta?.alias || [],
    redirect: TestimonialaQSbcI0K9rMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCard46VVD0tTjzMeta?.name ?? "landing-mobile-app-1-components-TestimonialCard",
    path: TestimonialCard46VVD0tTjzMeta?.path ?? "/landing/mobile-app-1/components/TestimonialCard",
    meta: TestimonialCard46VVD0tTjzMeta || {},
    alias: TestimonialCard46VVD0tTjzMeta?.alias || [],
    redirect: TestimonialCard46VVD0tTjzMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: dataqTFqpmeMWSMeta?.name ?? "landing-mobile-app-1-data",
    path: dataqTFqpmeMWSMeta?.path ?? "/landing/mobile-app-1/data",
    meta: dataqTFqpmeMWSMeta || {},
    alias: dataqTFqpmeMWSMeta?.alias || [],
    redirect: dataqTFqpmeMWSMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/data.ts").then(m => m.default || m)
  },
  {
    name: indexlzj2oa5G7iMeta?.name ?? "landing-mobile-app-1",
    path: indexlzj2oa5G7iMeta?.path ?? "/landing/mobile-app-1",
    meta: indexlzj2oa5G7iMeta || {},
    alias: indexlzj2oa5G7iMeta?.alias || [],
    redirect: indexlzj2oa5G7iMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/index.vue").then(m => m.default || m)
  },
  {
    name: AnchorNavigationQfRrFkjN2rMeta?.name ?? "landing-mobile-app-1-ThemeComponents-AnchorNavigation",
    path: AnchorNavigationQfRrFkjN2rMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/AnchorNavigation",
    meta: AnchorNavigationQfRrFkjN2rMeta || {},
    alias: AnchorNavigationQfRrFkjN2rMeta?.alias || [],
    redirect: AnchorNavigationQfRrFkjN2rMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue").then(m => m.default || m)
  },
  {
    name: BreadcrumbDKPVXwGo13Meta?.name ?? "landing-mobile-app-1-ThemeComponents-Breadcrumb",
    path: BreadcrumbDKPVXwGo13Meta?.path ?? "/landing/mobile-app-1/ThemeComponents/Breadcrumb",
    meta: BreadcrumbDKPVXwGo13Meta || {},
    alias: BreadcrumbDKPVXwGo13Meta?.alias || [],
    redirect: BreadcrumbDKPVXwGo13Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue").then(m => m.default || m)
  },
  {
    name: CustomSyntax7dk4WhG0krMeta?.name ?? "landing-mobile-app-1-ThemeComponents-CustomSyntax",
    path: CustomSyntax7dk4WhG0krMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/CustomSyntax",
    meta: CustomSyntax7dk4WhG0krMeta || {},
    alias: CustomSyntax7dk4WhG0krMeta?.alias || [],
    redirect: CustomSyntax7dk4WhG0krMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue").then(m => m.default || m)
  },
  {
    name: Jarallax9ZX15W8HKmMeta?.name ?? "landing-mobile-app-1-ThemeComponents-Jarallax",
    path: Jarallax9ZX15W8HKmMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/Jarallax",
    meta: Jarallax9ZX15W8HKmMeta || {},
    alias: Jarallax9ZX15W8HKmMeta?.alias || [],
    redirect: Jarallax9ZX15W8HKmMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue").then(m => m.default || m)
  },
  {
    name: LightGalleryrWOh4Nmx19Meta?.name ?? "landing-mobile-app-1-ThemeComponents-LightGallery",
    path: LightGalleryrWOh4Nmx19Meta?.path ?? "/landing/mobile-app-1/ThemeComponents/LightGallery",
    meta: LightGalleryrWOh4Nmx19Meta || {},
    alias: LightGalleryrWOh4Nmx19Meta?.alias || [],
    redirect: LightGalleryrWOh4Nmx19Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue").then(m => m.default || m)
  },
  {
    name: LoadingSpinnerk0mmBLzT12Meta?.name ?? "landing-mobile-app-1-ThemeComponents-LoadingSpinner",
    path: LoadingSpinnerk0mmBLzT12Meta?.path ?? "/landing/mobile-app-1/ThemeComponents/LoadingSpinner",
    meta: LoadingSpinnerk0mmBLzT12Meta || {},
    alias: LoadingSpinnerk0mmBLzT12Meta?.alias || [],
    redirect: LoadingSpinnerk0mmBLzT12Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue").then(m => m.default || m)
  },
  {
    name: ParallaxLnCgEdyAykMeta?.name ?? "landing-mobile-app-1-ThemeComponents-Parallax",
    path: ParallaxLnCgEdyAykMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/Parallax",
    meta: ParallaxLnCgEdyAykMeta || {},
    alias: ParallaxLnCgEdyAykMeta?.alias || [],
    redirect: ParallaxLnCgEdyAykMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue").then(m => m.default || m)
  },
  {
    name: PasswordInputoMRvEkRhv6Meta?.name ?? "landing-mobile-app-1-ThemeComponents-PasswordInput",
    path: PasswordInputoMRvEkRhv6Meta?.path ?? "/landing/mobile-app-1/ThemeComponents/PasswordInput",
    meta: PasswordInputoMRvEkRhv6Meta || {},
    alias: PasswordInputoMRvEkRhv6Meta?.alias || [],
    redirect: PasswordInputoMRvEkRhv6Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue").then(m => m.default || m)
  },
  {
    name: RellaxdGFPQSXwEXMeta?.name ?? "landing-mobile-app-1-ThemeComponents-Rellax",
    path: RellaxdGFPQSXwEXMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/Rellax",
    meta: RellaxdGFPQSXwEXMeta || {},
    alias: RellaxdGFPQSXwEXMeta?.alias || [],
    redirect: RellaxdGFPQSXwEXMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue").then(m => m.default || m)
  },
  {
    name: VanillaTilthplh39HAZAMeta?.name ?? "landing-mobile-app-1-ThemeComponents-VanillaTilt",
    path: VanillaTilthplh39HAZAMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/VanillaTilt",
    meta: VanillaTilthplh39HAZAMeta || {},
    alias: VanillaTilthplh39HAZAMeta?.alias || [],
    redirect: VanillaTilthplh39HAZAMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue").then(m => m.default || m)
  },
  {
    name: VideoPopupV6JRaCgoRXMeta?.name ?? "landing-mobile-app-1-ThemeComponents-VideoPopup",
    path: VideoPopupV6JRaCgoRXMeta?.path ?? "/landing/mobile-app-1/ThemeComponents/VideoPopup",
    meta: VideoPopupV6JRaCgoRXMeta || {},
    alias: VideoPopupV6JRaCgoRXMeta?.alias || [],
    redirect: VideoPopupV6JRaCgoRXMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue").then(m => m.default || m)
  },
  {
    name: Contact4wdtP2xNWxMeta?.name ?? "medical-components-Contact",
    path: Contact4wdtP2xNWxMeta?.path ?? "/medical/components/Contact",
    meta: Contact4wdtP2xNWxMeta || {},
    alias: Contact4wdtP2xNWxMeta?.alias || [],
    redirect: Contact4wdtP2xNWxMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: CtaLcn1udSS76Meta?.name ?? "medical-components-Cta",
    path: CtaLcn1udSS76Meta?.path ?? "/medical/components/Cta",
    meta: CtaLcn1udSS76Meta || {},
    alias: CtaLcn1udSS76Meta?.alias || [],
    redirect: CtaLcn1udSS76Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Cta.vue").then(m => m.default || m)
  },
  {
    name: FeatureH0mCzE6fFrMeta?.name ?? "medical-components-Feature",
    path: FeatureH0mCzE6fFrMeta?.path ?? "/medical/components/Feature",
    meta: FeatureH0mCzE6fFrMeta || {},
    alias: FeatureH0mCzE6fFrMeta?.alias || [],
    redirect: FeatureH0mCzE6fFrMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Feature.vue").then(m => m.default || m)
  },
  {
    name: FeatureCardT9epBAAvfKMeta?.name ?? "medical-components-FeatureCard",
    path: FeatureCardT9epBAAvfKMeta?.path ?? "/medical/components/FeatureCard",
    meta: FeatureCardT9epBAAvfKMeta || {},
    alias: FeatureCardT9epBAAvfKMeta?.alias || [],
    redirect: FeatureCardT9epBAAvfKMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/FeatureCard.vue").then(m => m.default || m)
  },
  {
    name: MedicalFooterii4CNIVJyVMeta?.name ?? "medical-components-MedicalFooter",
    path: MedicalFooterii4CNIVJyVMeta?.path ?? "/medical/components/MedicalFooter",
    meta: MedicalFooterii4CNIVJyVMeta || {},
    alias: MedicalFooterii4CNIVJyVMeta?.alias || [],
    redirect: MedicalFooterii4CNIVJyVMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/MedicalFooter.vue").then(m => m.default || m)
  },
  {
    name: MedicalHerovJI5qvvNfXMeta?.name ?? "medical-components-MedicalHero",
    path: MedicalHerovJI5qvvNfXMeta?.path ?? "/medical/components/MedicalHero",
    meta: MedicalHerovJI5qvvNfXMeta || {},
    alias: MedicalHerovJI5qvvNfXMeta?.alias || [],
    redirect: MedicalHerovJI5qvvNfXMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/MedicalHero.vue").then(m => m.default || m)
  },
  {
    name: NewsilndKsTtlnMeta?.name ?? "medical-components-News",
    path: NewsilndKsTtlnMeta?.path ?? "/medical/components/News",
    meta: NewsilndKsTtlnMeta || {},
    alias: NewsilndKsTtlnMeta?.alias || [],
    redirect: NewsilndKsTtlnMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/News.vue").then(m => m.default || m)
  },
  {
    name: NewsCardovlQINSJEeMeta?.name ?? "medical-components-NewsCard",
    path: NewsCardovlQINSJEeMeta?.path ?? "/medical/components/NewsCard",
    meta: NewsCardovlQINSJEeMeta || {},
    alias: NewsCardovlQINSJEeMeta?.alias || [],
    redirect: NewsCardovlQINSJEeMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/NewsCard.vue").then(m => m.default || m)
  },
  {
    name: ServiceCardENxhE2KNSHMeta?.name ?? "medical-components-ServiceCard",
    path: ServiceCardENxhE2KNSHMeta?.path ?? "/medical/components/ServiceCard",
    meta: ServiceCardENxhE2KNSHMeta || {},
    alias: ServiceCardENxhE2KNSHMeta?.alias || [],
    redirect: ServiceCardENxhE2KNSHMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/ServiceCard.vue").then(m => m.default || m)
  },
  {
    name: ServicesCLAXgYuXczMeta?.name ?? "medical-components-Services",
    path: ServicesCLAXgYuXczMeta?.path ?? "/medical/components/Services",
    meta: ServicesCLAXgYuXczMeta || {},
    alias: ServicesCLAXgYuXczMeta?.alias || [],
    redirect: ServicesCLAXgYuXczMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Services.vue").then(m => m.default || m)
  },
  {
    name: Team5TkSkepv8CMeta?.name ?? "medical-components-Team",
    path: Team5TkSkepv8CMeta?.path ?? "/medical/components/Team",
    meta: Team5TkSkepv8CMeta || {},
    alias: Team5TkSkepv8CMeta?.alias || [],
    redirect: Team5TkSkepv8CMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Team.vue").then(m => m.default || m)
  },
  {
    name: TeamCard5QwC4NPYq7Meta?.name ?? "medical-components-TeamCard",
    path: TeamCard5QwC4NPYq7Meta?.path ?? "/medical/components/TeamCard",
    meta: TeamCard5QwC4NPYq7Meta || {},
    alias: TeamCard5QwC4NPYq7Meta?.alias || [],
    redirect: TeamCard5QwC4NPYq7Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/TeamCard.vue").then(m => m.default || m)
  },
  {
    name: Testimonialk3vePHPIHMMeta?.name ?? "medical-components-Testimonial",
    path: Testimonialk3vePHPIHMMeta?.path ?? "/medical/components/Testimonial",
    meta: Testimonialk3vePHPIHMMeta || {},
    alias: Testimonialk3vePHPIHMMeta?.alias || [],
    redirect: Testimonialk3vePHPIHMMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardemUH1LWOKjMeta?.name ?? "medical-components-TestimonialCard",
    path: TestimonialCardemUH1LWOKjMeta?.path ?? "/medical/components/TestimonialCard",
    meta: TestimonialCardemUH1LWOKjMeta || {},
    alias: TestimonialCardemUH1LWOKjMeta?.alias || [],
    redirect: TestimonialCardemUH1LWOKjMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: VideoReelENFuf1alXBMeta?.name ?? "medical-components-VideoReel",
    path: VideoReelENFuf1alXBMeta?.path ?? "/medical/components/VideoReel",
    meta: VideoReelENFuf1alXBMeta || {},
    alias: VideoReelENFuf1alXBMeta?.alias || [],
    redirect: VideoReelENFuf1alXBMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/components/VideoReel.vue").then(m => m.default || m)
  },
  {
    name: datayZw48NNMY7Meta?.name ?? "medical-data",
    path: datayZw48NNMY7Meta?.path ?? "/medical/data",
    meta: datayZw48NNMY7Meta || {},
    alias: datayZw48NNMY7Meta?.alias || [],
    redirect: datayZw48NNMY7Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/data.ts").then(m => m.default || m)
  },
  {
    name: indexvIP8hZO7R3Meta?.name ?? "medical",
    path: indexvIP8hZO7R3Meta?.path ?? "/medical",
    meta: indexvIP8hZO7R3Meta || {},
    alias: indexvIP8hZO7R3Meta?.alias || [],
    redirect: indexvIP8hZO7R3Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/medical/index.vue").then(m => m.default || m)
  },
  {
    name: ArticleCardq0rYzkokDqMeta?.name ?? "news-id-components-ArticleCard",
    path: ArticleCardq0rYzkokDqMeta?.path ?? "/news/:id()/components/ArticleCard",
    meta: ArticleCardq0rYzkokDqMeta || {},
    alias: ArticleCardq0rYzkokDqMeta?.alias || [],
    redirect: ArticleCardq0rYzkokDqMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/ArticleCard.vue").then(m => m.default || m)
  },
  {
    name: ArticlesBTopPWYMxhMeta?.name ?? "news-id-components-Articles",
    path: ArticlesBTopPWYMxhMeta?.path ?? "/news/:id()/components/Articles",
    meta: ArticlesBTopPWYMxhMeta || {},
    alias: ArticlesBTopPWYMxhMeta?.alias || [],
    redirect: ArticlesBTopPWYMxhMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/Articles.vue").then(m => m.default || m)
  },
  {
    name: CommentKgFEpRkFZ2Meta?.name ?? "news-id-components-Comment",
    path: CommentKgFEpRkFZ2Meta?.path ?? "/news/:id()/components/Comment",
    meta: CommentKgFEpRkFZ2Meta || {},
    alias: CommentKgFEpRkFZ2Meta?.alias || [],
    redirect: CommentKgFEpRkFZ2Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/Comment.vue").then(m => m.default || m)
  },
  {
    name: CommentFormOHyBOwoFfnMeta?.name ?? "news-id-components-CommentForm",
    path: CommentFormOHyBOwoFfnMeta?.path ?? "/news/:id()/components/CommentForm",
    meta: CommentFormOHyBOwoFfnMeta || {},
    alias: CommentFormOHyBOwoFfnMeta?.alias || [],
    redirect: CommentFormOHyBOwoFfnMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/CommentForm.vue").then(m => m.default || m)
  },
  {
    name: Jarallax2KtPsIyATLMeta?.name ?? "news-id-components-Jarallax",
    path: Jarallax2KtPsIyATLMeta?.path ?? "/news/:id()/components/Jarallax",
    meta: Jarallax2KtPsIyATLMeta || {},
    alias: Jarallax2KtPsIyATLMeta?.alias || [],
    redirect: Jarallax2KtPsIyATLMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/Jarallax.vue").then(m => m.default || m)
  },
  {
    name: LightGalleryO80oNX9KetMeta?.name ?? "news-id-components-LightGallery",
    path: LightGalleryO80oNX9KetMeta?.path ?? "/news/:id()/components/LightGallery",
    meta: LightGalleryO80oNX9KetMeta || {},
    alias: LightGalleryO80oNX9KetMeta?.alias || [],
    redirect: LightGalleryO80oNX9KetMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/LightGallery.vue").then(m => m.default || m)
  },
  {
    name: SinglePostHeromluK9JdGXUMeta?.name ?? "news-id-components-SinglePostHero",
    path: SinglePostHeromluK9JdGXUMeta?.path ?? "/news/:id()/components/SinglePostHero",
    meta: SinglePostHeromluK9JdGXUMeta || {},
    alias: SinglePostHeromluK9JdGXUMeta?.alias || [],
    redirect: SinglePostHeromluK9JdGXUMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/components/SinglePostHero.vue").then(m => m.default || m)
  },
  {
    name: dataDAxZt1dqXUMeta?.name ?? "news-id-data",
    path: dataDAxZt1dqXUMeta?.path ?? "/news/:id()/data",
    meta: dataDAxZt1dqXUMeta || {},
    alias: dataDAxZt1dqXUMeta?.alias || [],
    redirect: dataDAxZt1dqXUMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/data.ts").then(m => m.default || m)
  },
  {
    name: indexJ4m82KR09XMeta?.name ?? "news-id",
    path: indexJ4m82KR09XMeta?.path ?? "/news/:id()",
    meta: indexJ4m82KR09XMeta || {},
    alias: indexJ4m82KR09XMeta?.alias || [],
    redirect: indexJ4m82KR09XMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: BlogCardJ5Q2ZB7eNaMeta?.name ?? "news-components-BlogCard",
    path: BlogCardJ5Q2ZB7eNaMeta?.path ?? "/news/components/BlogCard",
    meta: BlogCardJ5Q2ZB7eNaMeta || {},
    alias: BlogCardJ5Q2ZB7eNaMeta?.alias || [],
    redirect: BlogCardJ5Q2ZB7eNaMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/components/BlogCard.vue").then(m => m.default || m)
  },
  {
    name: ContentYqF62bXfDYMeta?.name ?? "news-components-Content",
    path: ContentYqF62bXfDYMeta?.path ?? "/news/components/Content",
    meta: ContentYqF62bXfDYMeta || {},
    alias: ContentYqF62bXfDYMeta?.alias || [],
    redirect: ContentYqF62bXfDYMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/components/Content.vue").then(m => m.default || m)
  },
  {
    name: Paginationc3taJjoAoAMeta?.name ?? "news-components-Pagination",
    path: Paginationc3taJjoAoAMeta?.path ?? "/news/components/Pagination",
    meta: Paginationc3taJjoAoAMeta || {},
    alias: Paginationc3taJjoAoAMeta?.alias || [],
    redirect: Paginationc3taJjoAoAMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/components/Pagination.vue").then(m => m.default || m)
  },
  {
    name: dataEtkNwyrYeGMeta?.name ?? "news-data",
    path: dataEtkNwyrYeGMeta?.path ?? "/news/data",
    meta: dataEtkNwyrYeGMeta || {},
    alias: dataEtkNwyrYeGMeta?.alias || [],
    redirect: dataEtkNwyrYeGMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/data.ts").then(m => m.default || m)
  },
  {
    name: index58NQmxYdQ7Meta?.name ?? "news",
    path: index58NQmxYdQ7Meta?.path ?? "/news",
    meta: index58NQmxYdQ7Meta || {},
    alias: index58NQmxYdQ7Meta?.alias || [],
    redirect: index58NQmxYdQ7Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexGp6cIprigZMeta?.name ?? "our-clients",
    path: indexGp6cIprigZMeta?.path ?? "/our-clients",
    meta: indexGp6cIprigZMeta || {},
    alias: indexGp6cIprigZMeta?.alias || [],
    redirect: indexGp6cIprigZMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/our-clients/index.vue").then(m => m.default || m)
  },
  {
    name: indexexGx1n1OlgMeta?.name ?? "partners",
    path: indexexGx1n1OlgMeta?.path ?? "/partners",
    meta: indexexGx1n1OlgMeta || {},
    alias: indexexGx1n1OlgMeta?.alias || [],
    redirect: indexexGx1n1OlgMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: indexv5wfaEd2YYMeta?.name ?? "payment-redirect",
    path: indexv5wfaEd2YYMeta?.path ?? "/payment/redirect",
    meta: indexv5wfaEd2YYMeta || {},
    alias: indexv5wfaEd2YYMeta?.alias || [],
    redirect: indexv5wfaEd2YYMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/payment/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: ContentHApWkEqOs6Meta?.name ?? "privacy-policy-components-Content",
    path: ContentHApWkEqOs6Meta?.path ?? "/privacy-policy/components/Content",
    meta: ContentHApWkEqOs6Meta || {},
    alias: ContentHApWkEqOs6Meta?.alias || [],
    redirect: ContentHApWkEqOs6Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/privacy-policy/components/Content.vue").then(m => m.default || m)
  },
  {
    name: indexp4bAzB3xz3Meta?.name ?? "privacy-policy",
    path: indexp4bAzB3xz3Meta?.path ?? "/privacy-policy",
    meta: indexp4bAzB3xz3Meta || {},
    alias: indexp4bAzB3xz3Meta?.alias || [],
    redirect: indexp4bAzB3xz3Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexmY4KGrL1poMeta?.name ?? "sales-agent-form",
    path: indexmY4KGrL1poMeta?.path ?? "/sales-agent-form",
    meta: indexmY4KGrL1poMeta || {},
    alias: indexmY4KGrL1poMeta?.alias || [],
    redirect: indexmY4KGrL1poMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/sales-agent-form/index.vue").then(m => m.default || m)
  },
  {
    name: indexv1tEafQWP2Meta?.name ?? "success",
    path: indexv1tEafQWP2Meta?.path ?? "/success",
    meta: indexv1tEafQWP2Meta || {},
    alias: indexv1tEafQWP2Meta?.alias || [],
    redirect: indexv1tEafQWP2Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/success/index.vue").then(m => m.default || m)
  },
  {
    name: Content4ALuKc8WuvMeta?.name ?? "terms-and-condition-components-Content",
    path: Content4ALuKc8WuvMeta?.path ?? "/terms-and-condition/components/Content",
    meta: Content4ALuKc8WuvMeta || {},
    alias: Content4ALuKc8WuvMeta?.alias || [],
    redirect: Content4ALuKc8WuvMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/terms-and-condition/components/Content.vue").then(m => m.default || m)
  },
  {
    name: indexnjKZ45cuOWMeta?.name ?? "terms-and-condition",
    path: indexnjKZ45cuOWMeta?.path ?? "/terms-and-condition",
    meta: indexnjKZ45cuOWMeta || {},
    alias: indexnjKZ45cuOWMeta?.alias || [],
    redirect: indexnjKZ45cuOWMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/terms-and-condition/index.vue").then(m => m.default || m)
  },
  {
    name: indexOGyJkDqnAZMeta?.name ?? "trailer-inspection-and-half-trailers",
    path: indexOGyJkDqnAZMeta?.path ?? "/trailer-inspection-and-half-trailers",
    meta: indexOGyJkDqnAZMeta || {},
    alias: indexOGyJkDqnAZMeta?.alias || [],
    redirect: indexOGyJkDqnAZMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/trailer-inspection-and-half-trailers/index.vue").then(m => m.default || m)
  },
  {
    name: indexxYxpfWDFg7Meta?.name ?? "trim",
    path: indexxYxpfWDFg7Meta?.path ?? "/trim",
    meta: indexxYxpfWDFg7Meta || {},
    alias: indexxYxpfWDFg7Meta?.alias || [],
    redirect: indexxYxpfWDFg7Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/trim/index.vue").then(m => m.default || m)
  },
  {
    name: indexK7uR2g3gu8Meta?.name ?? "vin-decoder",
    path: indexK7uR2g3gu8Meta?.path ?? "/vin-decoder",
    meta: indexK7uR2g3gu8Meta || {},
    alias: indexK7uR2g3gu8Meta?.alias || [],
    redirect: indexK7uR2g3gu8Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/vin-decoder/index.vue").then(m => m.default || m)
  },
  {
    name: indexR4G8fXNmh6Meta?.name ?? "warranties",
    path: indexR4G8fXNmh6Meta?.path ?? "/warranties",
    meta: indexR4G8fXNmh6Meta || {},
    alias: indexR4G8fXNmh6Meta?.alias || [],
    redirect: indexR4G8fXNmh6Meta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranties/index.vue").then(m => m.default || m)
  },
  {
    name: index5ZvmeyB6NuMeta?.name ?? "warranty-form",
    path: index5ZvmeyB6NuMeta?.path ?? "/warranty-form",
    meta: index5ZvmeyB6NuMeta || {},
    alias: index5ZvmeyB6NuMeta?.alias || [],
    redirect: index5ZvmeyB6NuMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty-form/index.vue").then(m => m.default || m)
  },
  {
    name: AppFeatureJhLbvTfm5BMeta?.name ?? "warranty-components-AppFeature",
    path: AppFeatureJhLbvTfm5BMeta?.path ?? "/warranty/components/AppFeature",
    meta: AppFeatureJhLbvTfm5BMeta || {},
    alias: AppFeatureJhLbvTfm5BMeta?.alias || [],
    redirect: AppFeatureJhLbvTfm5BMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/AppFeature.vue").then(m => m.default || m)
  },
  {
    name: CarSearchBox3LQxjYJD0VMeta?.name ?? "warranty-components-CarSearchBox",
    path: CarSearchBox3LQxjYJD0VMeta?.path ?? "/warranty/components/CarSearchBox",
    meta: CarSearchBox3LQxjYJD0VMeta || {},
    alias: CarSearchBox3LQxjYJD0VMeta?.alias || [],
    redirect: CarSearchBox3LQxjYJD0VMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/CarSearchBox.vue").then(m => m.default || m)
  },
  {
    name: Pricing2uNtK6buSXMeta?.name ?? "warranty-components-Pricing",
    path: Pricing2uNtK6buSXMeta?.path ?? "/warranty/components/Pricing",
    meta: Pricing2uNtK6buSXMeta || {},
    alias: Pricing2uNtK6buSXMeta?.alias || [],
    redirect: Pricing2uNtK6buSXMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Pricing.vue").then(m => m.default || m)
  },
  {
    name: PricingForMobilec5GhldnCqlMeta?.name ?? "warranty-components-PricingForMobile",
    path: PricingForMobilec5GhldnCqlMeta?.path ?? "/warranty/components/PricingForMobile",
    meta: PricingForMobilec5GhldnCqlMeta || {},
    alias: PricingForMobilec5GhldnCqlMeta?.alias || [],
    redirect: PricingForMobilec5GhldnCqlMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/PricingForMobile.vue").then(m => m.default || m)
  },
  {
    name: Saas3HeropxLGvZOQtaMeta?.name ?? "warranty-components-Saas3Hero",
    path: Saas3HeropxLGvZOQtaMeta?.path ?? "/warranty/components/Saas3Hero",
    meta: Saas3HeropxLGvZOQtaMeta || {},
    alias: Saas3HeropxLGvZOQtaMeta?.alias || [],
    redirect: Saas3HeropxLGvZOQtaMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Saas3Hero.vue").then(m => m.default || m)
  },
  {
    name: Testimonial9RhcLsPTrBMeta?.name ?? "warranty-components-Testimonial",
    path: Testimonial9RhcLsPTrBMeta?.path ?? "/warranty/components/Testimonial",
    meta: Testimonial9RhcLsPTrBMeta || {},
    alias: Testimonial9RhcLsPTrBMeta?.alias || [],
    redirect: Testimonial9RhcLsPTrBMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Testimonial.vue").then(m => m.default || m)
  },
  {
    name: TestimonialCardZVlpyMNdYEMeta?.name ?? "warranty-components-TestimonialCard",
    path: TestimonialCardZVlpyMNdYEMeta?.path ?? "/warranty/components/TestimonialCard",
    meta: TestimonialCardZVlpyMNdYEMeta || {},
    alias: TestimonialCardZVlpyMNdYEMeta?.alias || [],
    redirect: TestimonialCardZVlpyMNdYEMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/TestimonialCard.vue").then(m => m.default || m)
  },
  {
    name: Work1DnjVnLR8lMeta?.name ?? "warranty-components-Work",
    path: Work1DnjVnLR8lMeta?.path ?? "/warranty/components/Work",
    meta: Work1DnjVnLR8lMeta || {},
    alias: Work1DnjVnLR8lMeta?.alias || [],
    redirect: Work1DnjVnLR8lMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/components/Work.vue").then(m => m.default || m)
  },
  {
    name: datasEnBISx73UMeta?.name ?? "warranty-data",
    path: datasEnBISx73UMeta?.path ?? "/warranty/data",
    meta: datasEnBISx73UMeta || {},
    alias: datasEnBISx73UMeta?.alias || [],
    redirect: datasEnBISx73UMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/data.ts").then(m => m.default || m)
  },
  {
    name: indexwXkA4sWPgsMeta?.name ?? "warranty",
    path: indexwXkA4sWPgsMeta?.path ?? "/warranty",
    meta: indexwXkA4sWPgsMeta || {},
    alias: indexwXkA4sWPgsMeta?.alias || [],
    redirect: indexwXkA4sWPgsMeta?.redirect,
    component: () => import("C:/Users/SAYTECH/Downloads/Cartrust/cartrust-website/pages/warranty/index.vue").then(m => m.default || m)
  }
]